import { JsonMapper } from '../modules';
import { TransferHttp } from '../modules/transfer-http/transfer-http';
import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { BaseService } from './base.service';
import { TokenModel } from '../models';
import { REST_API } from '../modules/constants';
import { ResponseModel } from '../models';

@Injectable()
export class AuthService extends BaseService {

  constructor(protected _http: TransferHttp) {
    super(_http);
  }

  /**
   * Login
   */

  public login(data): Promise<TokenModel> {
    const obj = JsonMapper.serialize(data);
    return this.makeHttpPost(`${this.apiUrl}/` + REST_API.AUTH.LOGIN, obj)
      .then((res) => {
        return JsonMapper.deserialize(TokenModel, res);
      });
  }

  /**
   * forgot password
   */

  public forgotPassword(email: string): Promise<ResponseModel> {
    return this.makeHttpPost(`${this.apiUrl}/` + REST_API.AUTH.FORGOT_PASSWORD, { email: email});
  }


  /**
   * Logout
   */
  public logout() {
    return this.makeHttpDelete(`${this.apiUrl}/` + REST_API.AUTH.LOGOUT);
  }
}
