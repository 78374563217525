import { RouteInfo } from './sidebar.metadata';
import { ROLE } from 'app/modules';

// Sidebar menu Routes and data
export const ROUTES: RouteInfo[] = [
    // {
    //     path: '/dashboard',
    //     title: 'Dashboard',
    //     icon: 'ft-home',
    //     class: '',
    //     badge: '',
    //     badgeClass: '',
    //     isExternalLink: false,
    //     submenu: [],
    //     privileges: [ROLE.SYSTEM_ADMIN, ROLE.MANAGER]
    // },
    {
        path: '/ministry',
        title: 'Ministry',
        icon: 'ft-briefcase',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
        privileges: [ROLE.SYSTEM_ADMIN, ROLE.MANAGER]
    },
    {
        path: '/media',
        title: 'Media',
        icon: 'ft-box',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
        privileges: [ROLE.SYSTEM_ADMIN, ROLE.MANAGER]
    },
    // {
    //     path: '/settings',
    //     title: 'Settings',
    //     icon: 'ft-settings',
    //     class: '',
    //     badge: '',
    //     badgeClass: '',
    //     isExternalLink: false,
    //     submenu: [],
    //     privileges: [ROLE.SYSTEM_ADMIN, ROLE.MANAGER]
    // },
];
