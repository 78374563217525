export const setting = {
    rest: {
        apiUrl: "https://baptittinhthuong-rest.ventuso.net/api/v2",
        mediaUrl: "https://baptittinhthuong-media.ventuso.net"
    },
    system: {
        applicationName: "APP_NAME",
        applicationUrl: ""
    },
    seo: {
        defaultPageTitle: "DEFAULT_TITLE",
        pageTitlePositioning: 10,
        pageTitleSeparator: " | ",
        defaultMetaDescription: "DEFAULT_META_DESCRIPTION",
        defaultMetaKeyword: "DEFAULT_META_KEYWORD",
        defaultMetaImage: "DEFAULT_META_IMAGE"
    },
    i18n: {
        defaultLanguage: {
            code: "vi",
            name: "Vietnamese",
            culture: "vi-VN",
            img: "assets/i18n/flags/vi.png"
        },
        availableLanguages: [
            {
                code: "en",
                name: "English",
                culture: "en-US",
                img: "assets/i18n/flags/en.png"
            },
            {
                code: "vi",
                name: "Vietnamese",
                culture: "vi-VN",
                img: "assets/i18n/flags/vi.png"
            }
        ],
        autoDetectLanguage: true,
        useLocalizedRoutes: true
    },
    googleMap: {
        apiKey: "AIzaSyCsiAtjeWhUeb0YD8W4y4F3vggOQYcO_yU",
        latitude: 10.740280,
        longitude: 106.735930,
        zoom: 5
    },
    firebase: {
        apiKey: "AIzaSyAhD9ev4Kh_IFHsoThfTl3_e9E3RZOejGM",
        authDomain: "restaurant-dev-e35ad.firebaseapp.com",
        databaseURL: "https://restaurant-dev-e35ad.firebaseio.com",
        projectId: "restaurant-dev-e35ad",
        storageBucket: "",
        messagingSenderId: "298952977442",
        appId: "1:298952977442:web:82c5e70c08440d4a"
    }
};