import { VenueTypeModel } from './venue_type.model';
import { JsonMapper } from '../modules/mapper/json.mapper';
import { BaseModel, JsonDate } from './base.model';
import {
  INPUT_MAXLENGTH,
} from '../modules/constants';
import { ValidateModel } from './validate.model';
import { Json } from '../modules';
import { RoleModel } from './role.model';
import { MediaModel } from './media.model';

export class UserModel extends BaseModel {
  @Json('email')
  public email: string = undefined;

  @Json('oldPassword')
  public oldPassword: string = undefined;

  @Json('newPassword')
  public newPassword: string = undefined;

  @Json('password')
  public password: string = undefined;

  @Json('passwordAgain')
  public passwordAgain: string = undefined;

  @Json('name')
  public name: string = undefined;

  @Json('roleId')
  public roleId: string = undefined;

  @Json({ name: 'userTypeInfo', clazz: RoleModel, omitEmpty: true, ignore: true })
  public role: RoleModel = undefined;

  @Json('phoneNumber')
  public phoneNumber: string = undefined;

  @Json('gender')
  public gender: string = undefined;

  @Json('dayOfBirth')
  public dayOfBirth: string = undefined;

  @Json('avatarId')
  public avatarId: string = undefined;

  @Json({ name: 'avatar', clazz: MediaModel, omitEmpty: true })
  public avatar: MediaModel = undefined;

  @Json('venueLogoId')
  public venueLogoId: string = undefined;

  @Json({ name: 'venueLogo', clazz: MediaModel, omitEmpty: true })
  public venueLogo: MediaModel = undefined;

  @Json('venueBannerId')
  public venueBannerId: string = undefined;

  @Json({ name: 'venueBanner', clazz: MediaModel, omitEmpty: true })
  public venueBanner: MediaModel = undefined;

  @Json('venueCoverId')
  public venueCoverId: string = undefined;

  @Json({ name: 'venueCover', clazz: MediaModel, omitEmpty: true })
  public venueCover: MediaModel = undefined;

  @Json('venueName')
  public venueName: string = undefined;

  @Json('venueContactNumber')
  public venueContactNumber: string = undefined;

  @Json('venueWebsite')
  public venueWebsite: string = undefined;

  @Json('venueFacebook')
  public venueFacebook: string = undefined;

  @Json('venueTwitter')
  public venueTwitter: string = undefined;

  @Json('venueTypeId')
  public venueTypeId: string = undefined;

  @Json({ name: 'venueType', clazz: VenueTypeModel, omitEmpty: true })
  public venueType: VenueTypeModel = undefined;

  @Json('fullAddress')
  public fullAddress: string = undefined;

  @Json('address')
  public address: string = undefined;

  @Json('country')
  public country: string = undefined;

  @Json('state')
  public state: string = undefined;

  @Json('city')
  public city: string = undefined;

  @Json('isEncrypted')
  public isEncrypted: boolean = false;

  @Json('pin')
  public pin: any = undefined;

  @Json('latitude')
  public latitude: string = undefined;

  @Json('longitude')
  public longitude: string = undefined;

  @Json('isSystemAdmin')
  public isSystemAdmin: boolean = undefined;

  constructor() {
    super();
    this.validateRules = new ValidateModel();
    this.initValidateRules();
  }

  /**
   * init validate rule
   * @returns {ValidateModel}
   */
  public initValidateRules(): ValidateModel {
    this.addRule('name', 'required', true, this._t('Name can not be blank.'));

    this.addRule('phoneNumber', 'required', true, this._t('Phone number can not be blank.'));
    this.addRule('phoneNumber', 'number', true, this._t('Phone number must be digits.'));

    this.addRule('email', 'required', true, this._t('Email can not be blank.'));
    this.addRule('email', 'formatEmail', true, this._t('Email is invalid.'));

    this.addRule('oldPassword', 'required', true, this._t('Old password can not be blank.'));
    this.addRule('newPassword', 'required', true, this._t('New password can not be blank.'));
    this.addRule('password', 'required', true, this._t('Password can not be blank.'));
    this.addRule('passwordAgain', 'equalTo', '#password', this._t('Password are not matched.'));
    this.addRule('roleId', 'required', true, this._t('Role can not be blank.'));

    this.addRule('venueName', 'required', true, this._t('Please enter this required field'));
    this.addRule('venueContactNumber', 'required', true, this._t('Please enter this required field'));
    this.addRule('venueTypeId', 'required', true, this._t('Please enter this required field'));
    this.addRule('venueWebsite', 'website', true, this._t('Please enter a valid url'));
    this.addRule('venueFacebook', 'website', true, this._t('Please enter a valid url'));
    this.addRule('venueTwitter', 'website', true, this._t('Please enter a valid url'));

    return this.getRules();
  }

  /**
   *
   * @param data
   * @returns {UserModel}
   */
  public static toProfileModel(data: UserModel): UserModel {
    const model = JsonMapper.deserialize(UserModel, data);
    delete model.validateRules; // remove object validate rules
    return model;
  }
}