import { Routes } from '@angular/router';

// Route for content layout with sidebar, navbar and footer.
export const Full_ROUTES: Routes = [
  {
    path: 'dashboard',
    loadChildren: 'app/pages/dashboard/dashboard.module#DashboardModule'
  },
  {
    path: 'users',
    loadChildren: 'app/pages/users/user.module#UserModule'
  },
  {
    path: 'media',
    loadChildren: 'app/pages/media/media.module#MediaModule'
  },
  {
    path: 'ministry',
    loadChildren: 'app/pages/ministry/ministry.module#MinistryModule'
  },
  {
    path: 'settings',
    loadChildren: 'app/pages/settings/setting.module#SettingModule'
  },
  {
    path: 'account',
    loadChildren: 'app/pages/account/account.module#AccountModule'
  }
];