/**
 * Created by phuongho on 10/14/16.
 */
export const HTTP_METHOD = {
  GET: 'GET',
  POST: 'POST',
  PUT: 'PUT',
  DELETE: 'DELETE'
};

export const AUTHORIZATION = {
  TYPE: 'Authorization',
  METHOD: 'Bearer'
};

export const COOKIE = {
  FIREBASE_TOKEN_KEYWORD: 'firebase_token',
  TOKEN_KEYWORD: 'token',
  PROFILE_KEYWORD: 'profile',
  LANGUAGE_KEYWORD: 'language'
};

export const SESSION = {
  FIREBASE_TOKEN_KEYWORD: 'firebase_token',
  TOKEN_KEYWORD: 'token',
  PROFILE_KEYWORD: 'profile',
  LANGUAGE_KEYWORD: 'language',
  NEXT_URL_KEYWORD: 'nextUrl',
  PREV_URL_KEYWORD: 'prevUrl'
};

export const ERROR_CODE = {
  AUTHENTICATION: {
    GENERIC: 1100,
    VIOLATE_RFC6750: 1101,
    TOKEN_NOT_FOUND_CODE: 1102,
    NOT_AUTHORIZED_CODE: 1103,
    NOT_PERMISSION_ACCESS_CODE: 1104,
    WRONG_USER_OR_PASSWORD_CODE: 1105,
    INVALID_ACCESS_TOKEN_CODE: 1106,
    TOKEN_EXPIRED_CODE: 1107,
  },
  UNKNOWN: {
    MAINTENANCE_PERIOD: 3,
  }
};
export const ROLE = {
  SYSTEM_ADMIN: 'System',
  MANAGER: 'Manager',
  USER: 'User',
  VENUE: 'Venue',
};

export const PAGINATION = {
  MAX_SIZE: 5,
  ITEMS_PER_PAGE: 20,
  PAGE_ITEM_SIZE: [5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 100],
  BT_BUSINESS_ITEMS_PER_PAGE: 10,
  BT_AGENCY_ITEMS_PER_PAGE: 10,
};

export const HEADERS = {
  TOTAL_ITEMS: 'Total',
  ITEM_PER_PAGE: 'Item-Per-Page',
  CONTENT_DISPOSITION: 'Content-Disposition',
  CONTENT_TYPE: 'Content-Type',
  STATUS_CODE_SUCCESS: 200,
  DEVICE_ID: 'device-id',
  REGISTRAR_ID: 'registrar-id',
  USER_AGENT: 'userAgent',
  DEVICE_OS: 'device-os',
  APP_VERSION: 'app-version',
  LANGUAGE: 'language',
  TIMEZONE: 'timezone',
};

export const HTTP = {
  METHOD: {
    GET: 'GET',
    POST: 'POST',
    PUT: 'PUT',
    DELETE: 'DELETE',
  },
  CONTENT_TYPE: {
    JSON: 'application/json; charset=utf-8'
  },
  HEADER: {
    DEVICE_ID: 'website',
    DEVICE_OS: 'browsers',
    APP_VERSION: '1.0.0',
    USER_AGENT: 'browser',
  }
};

export const REST_API = {
  AUTH: {
    LOGIN: `auth`,
    FORGOT_PASSWORD: `auth/admin/forgot-password`,
    RESET_PASSWORD: `auth/reset-password`,
    LOGOUT: `auth`
  },
  USER: `users`,
  ME: {
    PROFILE: `me`,
    CHANGE_PASSWORD: `me/password`,
    FIREBASE_TOKEN: `auth/firebase`,
    UPDATE_DEVICE: `me/devices`,
  },
  ROLE: `roles`,
  MEDIA: `media`,
  FILE: `media/files`,
  SETTING: `settings`,
  CATEGORY: `categories`,
  COLOR: `colors`,
  SIZE: `sizes`,
  PRODUCT: `products`,
  POINT_TYPE: `point_types`,
  POINT: `points`,
  POST: `posts`,
  GALLERY: `galleries`,
  VENUE_TYPE: `venue-types`
};

export const IMAGE_ORIENTATION = {
  PORTRAIT: 'Portrait',
  LANDSCAPE: 'Landscape'
};

export const MAX_SIZE_UPLOAD = 10; // 10 MB
export const IMAGE_EXTENSION = 'jpg|jpeg|png|JPG|JPEG|PNG';
export const DOCUMENT_EXTENSION = 'pdf|PDF';
export const FILE_EXTENSION = 'csv';
export const ZIP_EXTENSION = 'zip';
export const MAX_TITLE = 255;
export const MAX_DESC = 2500;
export const MAX_LENGTH_PHONE = 11;
export const MAX_LENGTH_NUMBER = 255;
export const MAX_LENGTH_PASSWORD = 255;

export const MOMENT_DATE_FORMAT = {
  YYYY_MM_DD: 'YYYY-MM-DD',
  DD_MMM_YY: 'DD MMM YY',
  DD_MMM_YY_H_m: 'DD MMM YY H:m',
  MM_DD_YYYY: 'MM-DD-YYYY',
  DD_MM_YYYY: 'DD-MM-YYYY',
  YYYY_MM_DD_H_m: 'YYYY-MM-DD H:m',
  MM_DD_YYYY_H_m: 'MM-DD-YYYY H:m',
  DD_MM_YYYY_H_m: 'DD-MM-YYYY H:m',
  DD_MMMM_YYYY_hh_mm_A: 'DD MMMM YYYY, hh:mm A',
  HH_MM: 'HH:mm',
  h_mm_a: 'h:mm A',
  MM_YYYY: 'MMM-YYYY',
  SEARCH_CAR: 'DD MMMM',
  BS_MM_DD_YYYY: 'MM/DD/YYYY'
};

export const TIME_ZONE = {
  TIME_ZONE_DEFAULT: 'Asia/Ho_Chi_Minh',
  TIME_ZONE_UTC: 'UTC',
  VIETNAM: 'Asia/Ho_Chi_Minh',
};

export const LANGUAGE = {
  ENGLISH: 'en',
  VIETNAM: 'vi',
};

export const HTTP_CONNNECTION_TIMEOUT = 300000; // 5 minutes

export const MESSAGE_INFO = {
  MI_HTTP_CONNNECTION_TIMEOUT: 'Connection is timeout exceeded',
  MI_CHAT_SESSION_STOP: 'The chat session has been stopped',
};

export const DATEPICKER_FORMAT = {
  YYYY_MM_DD: 'yyyy-mm-dd',
};

export const DELETE_STATUS = {
  NO: false,
  YES: true
};

export const ENABLE_STATUS = {
  NO: false,
  YES: true
};

export const INPUT_MAXLENGTH = 255; // characters
export const INPUT_PASSWORD_MAXLENGTH = 6; // characters

export const SORT_TYPE = {
  ASC: 'ASC',
  DESC: 'DESC'
};

export const MIN_AUTO_COMPLETE_FILTER_CHAR = 2;
export const DEPLAY_AUTO_COMPLETE_FILTER_CHAR = 200;

export const CKEDITOR_CONFIG = {
  height: 600,
  debounce: 500
};


export const DISCOUNT_TYPE = {
  PERCENT: 'percent',
  AMOUNT: 'amount'
};

export const BOOKING_TIME_DEFAULT = {
  START_TIME: "21:00",
  END_TIME: "21:00",
};

export const DATETIMEPICKER_FORMAT = {
  YYYY_MM_DD: 'Y-m-d',
  MM_DD_YYYY: 'm-d-Y',
  DD_MMM_YY: 'd-M-y',
  DD_MMM_YY_H_i: 'd-M-y H:i',
  DD_MM_YYYY_H_i: 'M/d/yy h:mm a',
  DD_MM_YYYY: 'd-m-Y',
  YYYY_MM_DD_H_m: 'Y-m-d H:i',
  MM_DD_YYYY_H_m: 'm-d-Y H:m',
  DD_MM_YYYY_H_m: 'd-m-Y H:m',
  HH_MM_SSS: 'H:i',
  DD_MMM: 'd-M',
  MMM_YYYY: 'M-Y'
};

export const FIREBASE_DATABASE = {
  USERS: 'users',
  DEVICES: 'devices',
};
export const AVATAR_DEFAULT = 'assets/img/avatar.png';

export const IMAGE_THUMNAIL = {
  PRODUCT: {
    WIDTH: 80,
    HEIGHT: 120,
  },
  USER_AVATAR: {
    WIDTH: 100,
    HEIGHT: 100,
  },
  IMAGE_PRODUCT: {
    WIDTH: 157,
    HEIGHT: 237
  },
  USER_LOGO: {
    WIDTH: 200,
    HEIGHT: 200
  },
  USER_BANNER: {
    WIDTH: 1400,
    HEIGHT: 300
  },
  USER_COVER: {
    WIDTH: 838,
    HEIGHT: 289
  }
};

export const UPLOAD_IMAGE_TYPE = ['image/gif', 'image/jpeg', 'image/png'];
export const UPLOAD_FILE_TYPE = ['application/pdf', 'image/gif', 'image/jpeg', 'image/png'];
export const MAX_PRODUCT_IMAGE = 6;

export const POST_TYPE = {
  PAGE: 'page'
};

export const CROPPER_IMAGE = {
  OUT_PUT: 'png',
  ASPECT_RATIO: {
    IMAGE_LOGO: 1 / 1,
    IMAGE_BANNER: 14 / 3,
    IMAGE_COVER: 838 / 289,
  }
};

export const GALLERY_TYPE = {
  AUDIO: 'Audio',
  VIDEO: 'Video',
};