/**
 * Created by phuongho on 11/12/16.
 */
import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { CanActivate } from '@angular/router';
import { UserIdentityService } from './user_identity.service';
import { Observable } from 'rxjs';
import { ROLE } from 'app/modules';

@Injectable()
export class AuthGuard implements CanActivate {

  constructor(private _router: Router) {
  }

  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
    let isValid = true;
    const profile = UserIdentityService.getProfile();
    const routeData: any = route.data;
    if (!UserIdentityService.isLoggedIn()) {
      this._router.navigate(['login'], { queryParams: { return: state.url } });
      isValid = false;
    } else if (profile) {
      if (routeData && routeData.privileges && profile && profile.roleId
        && routeData.privileges.indexOf(profile.roleId) >= 0) {
        isValid = true;
      } else {
        isValid = false;
      }

      if (isValid === false) {
        switch (profile.roleId) {
          case ROLE.SYSTEM_ADMIN:
          case ROLE.MANAGER:
            this._router.navigate(['dashboard']);
            break;
          default:
            this._router.navigate(['profile']);
        }

      }
    }
    return isValid;
  }

}
