/**
 * Created by phuongho on 7/12/16.
 */
import { BaseModel } from './base.model';
import { Json } from '../modules';
import { ValidateModel } from './validate.model';

export class GalleryModel extends BaseModel {
  @Json('title')
  public title: string = undefined;

  @Json('description')
  public description: string = undefined;

  @Json('url')
  public url: string = undefined;

  @Json('type')
  public type: string = undefined;

  constructor() {
    super();
    this.validateRules = new ValidateModel();
    this.initValidateRules();
  }

  public initValidateRules(): ValidateModel {
    this.addRule('title', 'required', true, this._t('Title can not be blank.'));
    this.addRule('url', 'required', true, this._t('Url can not be blank.'));
    this.addRule('type', 'required', true, this._t('Type can not be blank.'));
    return this.getRules();
  }
}
